import { ServiceType } from "@bufbuild/protobuf";
import { createGrpcWebTransport } from "@connectrpc/connect-web";
import {
  Code,
  ConnectError,
  Interceptor,
  Transport,
} from "@connectrpc/connect";
import { createQueryService } from "@connectrpc/connect-query";
import { useMemo } from "react";
import { toast } from "react-toastify";

type ServiceApiName = "rhino" | "gnome" | "kakapo" | "hydra";

export const getToken = () => localStorage.getItem("token") || "";

export const uniconTransport = () => {
  return createGrpcWebTransport({
    baseUrl: `https://unicorn.n11s.io`,
    interceptors: [setToken(getToken()), loggerWithErrorHandler()],
  });
};

/**
 * Get a promise client for the given service.
 */
export function useClient<T extends ServiceType>(
  service: T,
  transport?: Transport
) {
  // We memoize the client, so that we only create one instance per service.
  return useMemo(
    () => createQueryService({ service, transport }),
    [service, transport]
  );
}

const setToken: (token?: string) => Interceptor = (token?: string) => {
  return (next) => async (req) => {
    if (token) req.header.set("Authorization", "Bearer " + token);
    return await next(req);
  };
};

const loggerWithErrorHandler: () => Interceptor = () => {
  return (next) => async (req) => {
    console.log(
      `%c gRPCClientRequest -> [${req.service.typeName} > ${req.method.name}] -> REQUEST:`,
      "background-color: #deeb34; color: #000; font-size: 14px",
      req
    );
    try {
      const res = await next(req);

      console.log(
        `%c>>>>> gRPCClientResponse -> [${req.service.typeName} > ${req.method.name}] -> SUCCESS:`,
        "background-color: #23d947; color: #000; font-size: 14px",
        res
      );

      return res;
    } catch (err) {
      const connectErr = ConnectError.from(err);
      switch (connectErr?.code) {
        case Code.Unauthenticated:
          console.log(
            `%c>>>>> gRPCClientResponse -> [${req.service.typeName} > ${req.method.name}] -> ERROR -> UNAUTHENTICATED: `,
            "background-color: #c0392b; color: #000; font-size: 14px",
            err
          );

          // window.location.href = "/sign-in";
          localStorage.removeItem("token");

          break;
        default:
          console.log(
            `%c>>>>> gRPCClientResponse  -> [${req.service.typeName}] > ${req.method.name}] -> ERROR: `,
            "background-color: #c0392b; color: #000; font-size: 14px",
            err
          );
          break;
      }

      toast.error(connectErr.message);
      return Promise.reject(connectErr);
    }
  };
};
