export const MENU_FOOTER = [
  {
    title: "NAUTILUS WEB MAP",
    menus: [
      {
        link: "#",
        title: "Home",
      },
      {
        link: "#",
        title: "Games",
      },
      {
        link: "#",
        title: "News",
      },
      {
        link: "#",
        title: "Math",
      },
      {
        link: "#",
        title: "Company",
      },
      {
        link: "#",
        title: "Events",
      },
      {
        link: "#",
        title: "Partners",
      },
    ],
  },
  {
    title: "ABOUT US",
    menus: [
      {
        link: "#",
        title: "Licensing",
      },
      {
        link: "#",
        title: "Certification",
      },
      {
        link: "#",
        title: "Responsible Gaming",
      },
      {
        link: "#",
        title: "Privacy Policy",
      },
      {
        link: "#",
        title: "Company",
      },
      {
        link: "#",
        title: "Events",
      },
      {
        link: "#",
        title: "Partners",
      },
    ],
  },
  {
    title: "EVENTS",
    menus: [
      {
        link: "#",
        title: "Nautilus 2019",
      },
      {
        link: "#",
        title: "Nautilus 2020",
      },
      {
        link: "#",
        title: "Nautilus 2021",
      },
      {
        link: "#",
        title: "About Events",
      },
    ],
  },
  {
    title: "OUR PARTNERS",
    menus: [
      {
        link: "#",
        title: "Huula Gaming",
      },
      {
        link: "#",
        title: "Leander Gaming",
      },
    ],
  },
];
