import React, { FC, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";
import Logo from "assets/images/logo.svg";
import Profile from "./Profile";
import Language from "./Language";
import { useLang } from "hooks/useLang";

interface IHeaderProps {
  show?: boolean;
}

const Header: FC<IHeaderProps> = ({ show }) => {
  const { injectLang } = useLang();

  useEffect(() => {
    const elt = document.getElementById("header");
    if (!elt) return;

    document.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        elt.classList.add(styles.show);
      } else {
        elt.classList.remove(styles.show);
      }
    });

    return () => {
      document.removeEventListener("scroll", () => {});
    };
  }, []);

  return (
    <div className={`${styles.header} ${show && styles.forceShow}`} id="header">
      <div className={styles.box}>
        <div className={styles.menu}></div>
        <div className={styles.logo}>
          <Link to={injectLang("/")}>
            <img src={Logo} alt="Logo" />
          </Link>
        </div>
        <div className={styles.rightContent}></div>
      </div>
    </div>
  );
};

export default Header;
