import * as React from "react";
import { useForm } from "react-hook-form";
import { Reception } from "@marketplace/mytenant-connect/mytenant_connect";
import { useMutation } from "@tanstack/react-query";

import { withMainLayout } from "layout/withMainLayout";
import styles from "./styles.module.scss";
import { uniconTransport, useClient } from "transport";
import { formatString, generateRandomString } from "utils";
import { toast } from "react-toastify";
import Input from "components/Input";
import BG_IMAGE from "assets/images/bg.png";
import Logo from "assets/images/logo.svg";
import Container from "components/Container";

interface IHomeProps {}

type TenantRegisterForm = {
  companyName: string;
  fullName: string;
  email: string;
};

const Home: React.FunctionComponent<IHomeProps> = (props) => {
  const [state, setState] = React.useState<"init" | "done">("init");
  const { register, handleSubmit } = useForm<TenantRegisterForm>({
    defaultValues: {
      companyName: "",
      fullName: "",
      email: "",
    },
  });

  const receptionCli = useClient(Reception, uniconTransport());
  const { mutateAsync: registerTenant, isPending } = useMutation(
    receptionCli.registerTenant.createUseMutationOptions()
  );

  const onSubmit = async (d: TenantRegisterForm) => {
    console.log("d", d);
    if (!d.companyName || !d.email || !d.fullName) {
      return toast.error(`Invalid request`);
    }

    const minifyName = `${formatString(d.fullName).substring(
      0,
      9
    )}${generateRandomString(3)}`;

    try {
      await registerTenant({
        org: {
          name: d.companyName,
          ownerName: d.fullName,
        },
        tenant: {
          name: d.companyName,
          clientSide: minifyName,
        },
        operator: {
          email: d.email,
          username: minifyName,
          metadata: `{"metadata": {"fullName": "${d.fullName}"}}`,
        },
      });

      toast.success(`Create Success`);
      setState("done");

      setTimeout(() => {
        setState("init");
      }, 5000);
    } catch (e) {
      console.log(e);
      toast.error(`${e}`);
    }
  };

  const registerDom = (
    <>
      <Input
        label="Company Name"
        placeholder="Enter your Company Name"
        register={register("companyName")}
      />
      <Input
        label="Full Name"
        placeholder="Enter your Full Name"
        register={register("fullName")}
      />
      <Input
        label="Email"
        placeholder="Enter your Email address"
        register={register("email")}
      />

      <button className={styles.loginButton} disabled={isPending}>
        <span>{isPending ? "Loading..." : "Register"}</span>
      </button>
    </>
  );

  const doneDom = (
    <>
      <div className={styles.textNotify}>
        After the successful creation process is completed, you will receive a
        notification email.
        <br />
        Please wait a few moments for us to finalize and send the email to you.
        <br />
        <br />
        <i>Back to form after 5s</i>
      </div>
    </>
  );

  return (
    <div
      className={styles.home}
      style={{ backgroundImage: `url(${BG_IMAGE})` }}
    >
      <Container className={styles.customContainer}>
        <div className={styles.loginContainer}>
          <img src={Logo} alt="Logo" />
          <br />
          <br />

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.loginForm}>
              <h1>Start your own business in no time!</h1>
              {state === "init" && registerDom}
              {state === "done" && doneDom}
            </div>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default withMainLayout(Home);
