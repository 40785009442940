export function formatString(str: string) {
  let formattedString = str.toLowerCase();
  formattedString = formattedString.replace(/[^a-z0-9]/g, "");
  return formattedString;
}

export function generateRandomString(length: number) {
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}
