import { useSearchParams } from "react-router-dom";

export const useLang = () => {
  const [query] = useSearchParams();

  const injectLang = (url: string) => {
    const q = url.includes("?");
    if (query.has("l")) return `${url}${q ? "&" : "?"}l=${query.get("l")}`;
    return url;
  };

  return { injectLang };
};
